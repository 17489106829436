import React, { useEffect, useRef, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { noop } from "lodash";
import ReactPlayer from "react-player";
import IconWithLabel from "./IconWithLabel";
import "../../styles/EventDetailsPopover.scss";

const DualVideoPlayer = ({ mainVideoUrl, secondaryVideoUrl }) => {
    const [videoError, setVideoError] = useState();
    const [showSecondary, setShowSecondary] = useState(true);
    const [playing, setPlaying] = useState(false);
    const secondaryPlayer = useRef(null);
    const mainPlayer = useRef(null);

    const handleFootageError = () => {
        setVideoError('Video is unavailible');
    }


    //check if seconds are less than half a second apart
    const isCloseTo = (seconds, target) => {
        return Math.abs(seconds - target) < 0.5;
    }
    const getPlayerConfig = () => {
        return {
            xhrSetup: function (xhr, url) {
                xhr.withCredentials = true; // do send cookies
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"));
            }
        }
    }
    const handleMainProgress = ({ playedSeconds }, isControled, opositeRef) => {
        if (isControled && opositeRef.current && !isCloseTo(playedSeconds, secondaryPlayer.current.getCurrentTime())) {
            opositeRef.current.seekTo(playedSeconds);
        }
    }
    const handleSeek = (seconds, isControled, opositeRef) => {
        if (isControled && opositeRef.current) {
            opositeRef.current.seekTo(seconds);
        }
    }

    const [framesOptions, setFramesOptions] = useState({
        main: {
            style: { width: "unset", height: "100%", objectFit: 'contain', position: 'relative' },
            config: getPlayerConfig(),
            controls: true,
            progressInterval: 500,
            onError: handleFootageError,
            onPlay: () => setPlaying(true),
            onPause: () => setPlaying(false),
            onClick: noop
        },
        secondary: {
            style: {
                width: "33.33%",
                height: "auto",
                top: 0,
                zIndex: "9",
                objectFit: "contain",
                position: "absolute",
                cursor: "pointer",
                right: 0,
            },
            config: getPlayerConfig(),
            controls: false,
            onError: () => setShowSecondary(false),
            onClick: (e) => { e.preventDefault(); replace(); }
        }
    });

    const replace = () => setFramesOptions((prev) => ({ main: prev.secondary, secondary: prev.main }));


    useEffect(() => {
        mainPlayer.current && (mainPlayer.current.wrapper.onClick = framesOptions.main.onClick);
        secondaryPlayer.current && (secondaryPlayer.current.wrapper.onClick = framesOptions.secondary.onClick);
    }, [framesOptions]);


    return (
        <div className="video-container">

            {(!videoError && mainVideoUrl) ?
                <>
                    <ReactPlayer
                        {...framesOptions.main}
                        width={framesOptions.main.style.width}
                        height={framesOptions.main.style.height}
                        url={mainVideoUrl}
                        playing={playing}
                        ref={mainPlayer}
                        onProgress={({ playedSeconds }) => handleMainProgress({ playedSeconds }, framesOptions.main.controls, secondaryPlayer)}
                        onSeek={(seconds) => handleSeek(seconds, framesOptions.main.controls, secondaryPlayer)}
                        onClick={(e) => { mainPlayer.current.wrapper.onClick(e) }}
                    />
                    {Boolean(secondaryVideoUrl && showSecondary) &&
                        <ReactPlayer
                            {...framesOptions.secondary}
                            width={framesOptions.secondary.style.width}
                            height={framesOptions.secondary.style.height}
                            ref={secondaryPlayer}
                            playing={playing}
                            url={secondaryVideoUrl}
                            onSeek={(seconds) => handleSeek(seconds, framesOptions.secondary.controls, mainPlayer)}
                            onProgress={({ playedSeconds }) => handleMainProgress({ playedSeconds }, framesOptions.secondary.controls, mainPlayer)}
                            onClick={(e) => { secondaryPlayer.current.wrapper.onClick(e) }}

                        />}
                </>
                :
                <IconWithLabel label={videoError} color='#b30000'>
                    <BiErrorCircle />
                </IconWithLabel>
            }
        </div>
    );
};

export default DualVideoPlayer;
