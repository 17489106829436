import { fetchApi } from '../utils/fetch';
import { assign, toPairs, map, join, isEmpty, isNull } from 'lodash';

const baseUrl = '/api/v2';
// const baseUrl =  'http://localhost:5000/api';

//Auth
export async function login(email, password) {
    //TODO - encode the password
    const body = { email, password };
    const res = await fetchApi.post(`${baseUrl}/auth/login`, body);
    storeUserAuthToken(res.token);
    return res;
}

export async function logout() {
    const res = await fetchApi.post(`${baseUrl}/auth/logout`, {});
    return res;
}


//Software
export async function getVersion() {
    const res = await fetchApi.get(`${baseUrl}/version`);
    return res;
}


//Device

/**
 * Returns all registered devices.
 * @param {Number} page Number of page
 * @param {Number} pageSize Number of records in a page
 * @param {String} filters Filters string separated by commas (',')
 * @param {String} sorts Sorts string separated by commas (',')
 */
export async function getDevices(page, pageSize, filters, sorts) {
    const headers = addBearerAuthToHeaders({});
    const geocoding = true;
    const params = { page, pageSize, filters, sorts, geocoding };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.get(`${baseUrl}/devices?${queryParams}`, headers);
    return res;
}

/**
 * Delete device.
 * @param {String} deviceId device id
 */
export async function deleteDevice(deviceId) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.delete(`${baseUrl}/device/${deviceId}`, headers);
    return res;
}

/**
 * Get device.
 * @param {String} deviceId device id
 */
export async function getDevice(deviceId) {
    const headers = addBearerAuthToHeaders({});
    const params = { deviceId };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.get(`${baseUrl}/device/${deviceId}?${queryParams}`, headers);
    return res;
}

/**
 * Add owner to device.
 * @param {String} deviceId device id
 * @param {String} email user email
 */
export async function addOwnerToDevice(deviceId, email) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.put(`${baseUrl}/device/${deviceId}/user/${email}`, {}, headers);
    return res;
}

/**
 * Remove owner from device.
 * @param {String} deviceId device id
 * @param {String} email user email
 */
export async function removeOwnerFromDevice(deviceId, email) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.delete(`${baseUrl}/device/${deviceId}/user/${email}`, headers);
    return res;
}

//Driver

/**
 * Assign driver to device, send driver details in order to insert or update.
 * @param {String} deviceId device id
 */
export async function addDriverToDevice(deviceId, driverId, driverName, email) {
    const headers = addBearerAuthToHeaders({});
    const body = { driverId, driverName, email };

    const res = await fetchApi.put(`${baseUrl}/device/${deviceId}/driver`, body, headers);
    return res;
}

/**
 * Remove driver from device
 * @param {String} deviceId device id
 * @param {String} driverId device id
 */
export async function removeDriverFromDevice(deviceId, driverId) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.delete(`${baseUrl}/device/${deviceId}/driver/${driverId}`, headers);
    return res;
}

/**
 * Get driver details from device by driver id.
 * @param {String} deviceId device id
 */
export async function getDriverDetailsById(deviceId, driverId) {
    const headers = addBearerAuthToHeaders({});
    const params = { deviceId, driverId };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.get(`${baseUrl}/device/${deviceId}/driver/${driverId}/details?${queryParams}`, headers);
    return res;
}

/**
 * Trigger sending a command from the backend application to capture the face currently looking at the camera and return the image.
 * @param {String} deviceId device id
 * @param {Number} driverId device id
 */
export async function captureFace(deviceId, driverId) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.post(`${baseUrl}/device/${deviceId}/driver/${driverId}/photo/capture`, {}, headers);
    return res;
}

/**
 * Trigger sending an image of driver's face from the backend application.
 * @param {String} deviceId device id
 * @param {Number} driverId device id
 */
export async function uploadDriverImage(deviceId, driverId, image) {
    const headers = addBearerAuthToHeaders({});
    const body = { image };

    const res = await fetchApi.post(`${baseUrl}/device/${deviceId}/driver/${driverId}/photo/upload`, body, headers);
    return res;
}


//Device actions

/**
 * Reset device.
 * @param {String} deviceId device id
 */
export async function resetDevice(deviceId) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.post(`${baseUrl}/device/${deviceId}/reset`, {}, headers);
    return res;
}

/**
 * Trigger the backend application to instruct the device to start recording NOW for ‘TimeToRecord' duration.
 * @param {String} deviceId device id
 * @param {Number} secondsToRecord duration in secs
 */
export async function startRecordingNow(deviceId, secondsToRecord) {
    const headers = addBearerAuthToHeaders({});
    const body = { secondsToRecord };

    const res = await fetchApi.post(`${baseUrl}/device/${deviceId}/record`, body, headers);
    return res;
}

//Message (event)

/**
 * Mark list of events as read.
 * @param {Array} ids Array of messages ids to be marked as read
 */
export async function markMessagesAsRead(ids) {
    const headers = addBearerAuthToHeaders({});
    const body = { ids };

    const res = await fetchApi.post(`${baseUrl}/message/read`, body, headers);
    return res;
}

/**
 * Get events.
 * @param {Number} page Number of page
 * @param {Number} pageSize Number of records in a page
 * @param {String} filters Filters string separated by commas (',')
 * @param {String} sorts Sorts string separated by commas (',')
 */
export async function getMessages(page, pageSize, filters, sorts) {
    const headers = addBearerAuthToHeaders({});
    const geocoding = true;
    const params = { page, pageSize, filters, sorts, geocoding };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.get(`${baseUrl}/message/events?${queryParams}`, headers);
    return res;
}
/**
 * Get events types.
 */
export async function getEventTypes() {
    const headers = addBearerAuthToHeaders({});
    const res = await fetchApi.get(`${baseUrl}/message/eventTypes`, headers)
    return res;
}

/**
 * Delete events between given start and end dates.
 * @param {Date} startDate Start date
 * @param {Date} endDate End date
 * @param {Boolean} unread By set it to true, get unread events
 */
export async function deleteMessages(startDate, endDate, unread) {
    const headers = addBearerAuthToHeaders({});
    const params = { startDate, endDate, unread };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.delete(`${baseUrl}/message/events?${queryParams}`, headers);
    return res;
}

/**
 * Get an image of an event by id.
 * @param {Number} messageId Message id 
 */
export function getImageByMessageId(deviceId, messageId, source, index) {
    let ret = addAccessTokenToQueryString(`${baseUrl}/message/image/${deviceId}/${messageId}?source=${source}&index=${index}`);
    return ret;
}

/**
 * Get a video of an event by id.
 * @param {String} deviceId Device id 
 * @param {Number} messageId Message id 
 */
export function getVideoByDeviceIdAndMessageId(deviceId, messageId, source) {
    return addAccessTokenToQueryString(`${baseUrl}/message/video/${deviceId}/${messageId}?source=${source}&index=0`);
}


// User

/**
 * Get all users (admin).
 * @param {Number} page Number of page
 * @param {Number} pageSize Number of records in a page
 * @param {String} filters Filters string separated by commas (',')
 * @param {String} sorts Sorts string separated by commas (',')
 */
export async function getAllUsers(page, pageSize, filters, sorts) {
    const headers = addBearerAuthToHeaders({});
    const params = { page, pageSize, filters, sorts };
    const queryParams = buildQueryString(params);

    const res = await fetchApi.get(`${baseUrl}/users?${queryParams}`, headers);
    return res;
}

/**
 * Insert or update user.
 * @param {String} username Username
 * @param {String} email Email
 * @param {String} role Role
 * @param {String} password Password
 */
export async function upsertUser(username, email, role) {
    const headers = addBearerAuthToHeaders({});
    const body = { username, email, role };

    const res = await fetchApi.post(`${baseUrl}/user`, body, headers);
    return res;
}

/**
 * Remove user (admin).
 * @param {String} email Email
 */
export async function removeUser(email) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.delete(`${baseUrl}/user/${email}`, headers);
    return res;
}

/**
 * Set password to user (admin).
 * @param {String} email Email
 * @param {String} password Password (encoded)
 */
export async function setPasswordToUser(email, password) {
    const headers = addBearerAuthToHeaders({});
    const body = { password };

    const res = await fetchApi.put(`${baseUrl}/user/${email}/password`, body, headers);
    return res;
}

/**
 * Assign device to user (admin).
 * @param {String} deviceId Device id
 * @param {String} email Email
 */
export async function assignDeviceToUser(deviceId, email) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.put(`${baseUrl}/device/${deviceId}/user/${email}`, {}, headers);
    return res;
}

/**
 * Un-assign device to user (admin).
 * @param {String} deviceId Device id
 * @param {String} email Email
 */
export async function unassignDeviceToUser(deviceId, email) {
    const headers = addBearerAuthToHeaders({});

    const res = await fetchApi.delete(`${baseUrl}/device/${deviceId}/user/${email}`, headers);
    return res;
}

/**
 * Get logo of the user's organization.
 * @param {String} email Email
 */
export async function getLogo(email) {
    return addAccessTokenToQueryString(`${baseUrl}/user/${email}/logo?`);
}

//Helpers functions
const buildQueryString = paramsObj => {
    if (isNull(paramsObj) || isEmpty(paramsObj)) {
        return '';
    }

    const keyValuePairsParams = toPairs(paramsObj);
    const populatedKeyValuePairsParams = keyValuePairsParams.filter(paramPair => paramPair[1] && paramPair[1] !== "");
    const mapKeyToQueryParam = pair => pair[0] + '=' + pair[1];

    return join(map(populatedKeyValuePairsParams, mapKeyToQueryParam), '&');
}

const storeUserAuthToken = token => {
    //TODO - add the given token to localStorage
    localStorage.setItem('token', token);

}

const addBearerAuthToHeaders = headers => {
    //TODO - retrieve the token from localStorage
    const token = localStorage.getItem('token');
    return token ? assign(headers, { Authorization: `Bearer ${token}` }) : headers;
}

const addAccessTokenToQueryString = queryString => {
    const token = localStorage.getItem('token');
    return `${queryString}&access_token=${token}`;
}
